export const dict = {
  login: {
    username: {
      required: 'Identifiant obligatoire'
    },
    password: {
      required: 'Mot de passe obligatoire'
    },
    error: 'Email ou mot de passe erroné'
  },
  task: {
    create: {
      start: {
        required: 'Date de début obligatoire'
      },
      end: {
        required: 'Date de fin obligatoire'
      },
      actions: {
        required: 'Il faut ajouter au moins une action obligatoirement'
      }
    }
  }
}

const rules = {
  required: (v) => !!v,
  length: (v) => v.length >= 1
}

export const validations = {
  login: {
    username: [rules.required || dict.login.username.required],
    password: [rules.required || dict.login.password.required]
  },
  task: {
    create: {
      start: [rules.required || dict.task.create.start.required],
      end: [rules.required || dict.task.create.end.required],
      actions: [
        rules.required || dict.task.create.actions.required,
        rules.length || dict.task.create.actions.required
      ]
    }
  }
}
