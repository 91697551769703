<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="save"
          >
            <v-row
              v-for="action in currentTask.timesheet"
              :key="action.id"
              class="d-flex align-center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <ActionLabel :action="action.type" />
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex align-center"
              >
                <v-text-field
                  :value="action.time"
                  prepend-icon="mdi-clock"
                  type="number"
                  suffix="heures"
                  hide-details="auto"
                  single-line
                  dense
                  @input="setTime($event, action)"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" class="ml-2" x-small @click="setToAllActionsTime(action)">
                      <v-icon x-small>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  Copier sur toutes les lignes
                </v-tooltip>
              </v-col>
            </v-row>
            <div class="d-flex mt-10">
              <v-spacer />
              <v-btn text @click="close">
                Annuler
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="save"
              >
                Enregistrer la tâche
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ActionLabel from '@/components/tasks/ActionLabel'
export default {
  components: { ActionLabel },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      currentTask: {
        users: []
      }
    }
  },
  async mounted () {
    this.currentTask = this.task //{ ...this.task }
  },
  methods: {
    setToAllActionsTime (action) {
      this.currentTask.timesheet.forEach((row) => {
        this.setTime(action.time, row)
      })
    },
    setTime (e, action) {
      action.time = e
      this.$forceUpdate()
    },
    close () {
      this.$emit('close')
    },
    save () {
      this.$refs.form.validate()
      this.$emit('save', this.currentTask)
    }
  }
}
</script>
