<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="save"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <DatePicker
                  v-if="currentTask.start"
                  :value="currentTask.start"
                  label="Date de début"
                  :rules="rules.start"
                  required
                  @updated="setStartDate"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker
                  v-if="currentTask.end"
                  :value="currentTask.end"
                  label="Date de fin"
                  :rules="rules.end"
                  :min="currentTask.start"
                  required
                  @updated="setEndDate"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Actions"
                  v-model="currentTask.actions"
                  :items="types.filter(type => type.typ_id.substring(0, 3) == 'TYP')"
                  item-text="typ_label"
                  item-value="typ_id"
                  chips
                  deletable-chips
                  multiple
                  prepend-icon="mdi-account-hard-hat"
                  required
                  :rules="rules.actions"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-if="custom"
                  v-model="currentTask.custom"
                  label="Prestation spéciale"
                />
                <v-checkbox
                  v-else
                  label="Prestation spéciale ?"
                  v-model="currentTask.custom"
                  prepend-icon="mdi-star"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Commentaire"
                  v-model="currentTask.comment"
                  rows="2"
                  prepend-icon="mdi-comment"
                />
              </v-col>
            </v-row>
            <div class="d-flex">
              <v-btn text color="red" @click="deleteTask" v-if="currentTask.id">
                Supprimer
              </v-btn>
              <v-spacer />
              <v-btn text @click="close">
                Annuler
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="save"
              >
                Enregistrer la tâche
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validations } from '@/services/validation'
import dateLocale from '@/services/dateLocale'
import DatePicker from '@/components/ui/DatePicker'

export default {
  components: {
    DatePicker
  },
  props: {
    task: {
      type: Object,
      required: false,
      default: () => ({
        start: dateLocale.formatDate(new Date()),
        end: dateLocale.formatDate(new Date()),
        actions: [],
        comment: '',
        custom: false,
        area: null,
        state: ''
      })
    },
    custom: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      valid: false,
      currentTask: {}
    }
  },
  computed: {
    rules () {
      return validations.task.create
    },
    ...mapGetters({
      types: 'app/types'
    })
  },
  watch: {
    task: {
      handler() {
        this.currentTask = { ...this.currentTask, ...this.task }
      },
      immediate: true
    }
  },
  methods: {
    setStartDate (date) {
      this.currentTask.start = date
      this.currentTask.end = date
    },
    setEndDate (date) {
      this.currentTask.end = date
    },
    close () {
      this.$emit('close')
    },
    save () {
      this.$refs.form.validate()
      this.$emit('save', this.currentTask)
    },
    reset () {
      this.currentTask = {}
    },
    deleteTask(){
      return new Promise( (resolve, reject) => {
          this.$http.delete( '/tasks/' + this.currentTask.id ).then( () => {
              this.close()
              resolve('resolved')
          }).catch( (error) => { 
              reject(error)
          })
      })
    }
  }
}
</script>
