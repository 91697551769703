<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="save"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <p>Vous pouvez associer des agents à cette tâche.</p>
                <v-autocomplete
                  label="Agents"
                  v-model="currentTask.users"
                  :items="formattedUsers"
                  item-text="name"
                  item-value="usr_id"
                  chips
                  deletable-chips
                  multiple
                  prepend-icon="mdi-account-multiple"
                />
              </v-col>
            </v-row>
            <div class="d-flex">
              <v-spacer />
              <v-btn text @click="close">
                Annuler
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="save"
              >
                Enregistrer la tâche
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      currentTask: {
        users: []
      },
      users: []
    }
  },
  computed: {
    formattedUsers () {
      return this.users.map((user) => {
        return {
          name: `${user.usr_lastName} ${user.usr_firstName}`.trim(),
          ...user
         }
      })
    }
  },
  async mounted () {
    this.currentTask = this.task //{ ...this.task }
    await this.loadData()
  },
  methods: {
    async loadData () {
      try {
        this.loading = true
        this.users = await this.fetchUsers( { role: 'AGENT' } )
      } catch (err) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    close () {
      this.$emit('close')
    },
    save () {
      this.$refs.form.validate()
      this.$emit('save', this.currentTask)
    },
    ...mapActions({
      fetchUsers: 'user/fetchUsers'
    })
  }
}
</script>
