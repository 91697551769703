<template>
  <v-menu ref="menu"
          v-model="open"
          :close-on-content-click="false"
          :close-on-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          style="z-index:1500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        :value="formattedDate"
        :label="label"
        prepend-icon="mdi-calendar-today"
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      locale="fr-fr"
      no-title
      scrollable
      :min="min"
      :max="max"
    >
      <v-btn color="red" @click="date = null"><v-icon>mdi-eraser</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-btn text @click="cancel">Annuler</v-btn>
      <v-btn color="primary" @click="save">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import dateLocale from '@/services/dateLocale'

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    value: {
      type: String,
      required: true
    },
    min: {
      type: String,
      required: false,
      default: () => null
    },
    max: {
      type: String,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      open: false,
      date: '',
      previous: ''
    }
  },
  computed: {
    formattedDate () {
      return this.date ? dateLocale.formatLocale(this.date, 'fr') : ''
    }
  },
  watch: {
    value: {
      handler () {
        this.date = this.value
      },
      immediate: true
    },
    min: {
      handler () {
        if (this.$date(this.date).isBefore(this.$date(this.min))) {
          this.date = this.min
        }
      },
      immediate: true
    },
    open: {
      handler () {
        this.previous = this.date
      },
      immediate: true
    }
  },
  methods: {
    cancel () {
      this.date = this.previous
      this.close()
    },
    close () {
      this.open = false
    },
    save () {
      this.$emit('updated', this.date)
      this.close()
    }
  }
}
</script>
